import * as React from 'react';
import { Link } from 'react-scroll';
import { AppBar, Box, Toolbar, Typography, Container, Button, IconButton, Menu, MenuItem, useTheme, styled } from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';

const pages = [
  { label: 'Home', path: 'home' },
  { label: 'About', path: 'about' },
  { label: 'Experience', path: 'experience' },
  { label: 'Projects', path: 'projects' },
  { label: 'Skills', path: 'skills' },
  { label: 'Education', path: 'education' },
  { label: 'Certifications', path: 'certification' },
  { label: 'Achievements', path: 'achievements' },
  { label: 'Awards', path: 'awards' },
  { label: 'Contact', path: 'contactMe' },
];

function NavBar() {
  const theme = useTheme();

  const StyledButton = styled(Button)({
    textTransform: 'capitalize',
  });

  const ActiveLink = styled(Link)({
    textDecoration: 'none',
    fontWeight: '600',
    [theme.breakpoints.between('sm', 'lg')]: { fontSize: '0.79rem' },
    position: 'relative',
    textTransform: 'capitalize',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-4px',
      width: '100%',
      height: '3px',
      backgroundColor: 'yellow',
      zIndex: 1,
      transform: 'scaleX(0)',
      transition: 'transform 0.6s ease',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
    },
    '&.active::after': {
      transform: 'scaleX(1)',
    },
  });

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <AppBar position="sticky" sx={{ height: 80, boxShadow: 0, backgroundColor: '#0c0c0c' }}>
      <Container maxWidth="xl">
        <Toolbar sx={{ height: 80 }} disableGutters>
          <Box sx={{ flexGrow: 1, paddingLeft: '2vw', [theme.breakpoints.between('sm', 'lg')]: {paddingLeft: '0.9vw'} }}>
            <Typography variant="body1" component="div" sx={{  display: 'flex', alignItems: 'center', fontSize: '1.2rem', [theme.breakpoints.between('sm', 'lg')]: {fontSize: '1rem'} }}>
              <Link to="home" spy={true} smooth={true} offset={-100} duration={100} style={{ textDecoration: 'none', color: 'inherit', fontWeight: '600', cursor: 'pointer' }}>
                Somesh
              </Link>
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '3px', paddingRight: '4vw', [theme.breakpoints.between('sm', 'lg')]: { gap: '0px', paddingRight: '0vw' } }} >
            {pages.map((page) => (
              <StyledButton key={page.label} color="inherit">
                <ActiveLink to={page.path} spy={true} smooth={true} offset={-80} duration={100}>
                  {page.label}
                </ActiveLink>
              </StyledButton>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              {pages.map((page) => (
                <MenuItem key={page.label}>
                  <ActiveLink to={page.path} spy={true} smooth={true} offset={-160} duration={100} onClick={handleMenuClose}>
                    {page.label}
                  </ActiveLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
