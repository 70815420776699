import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, useTheme, Avatar, Grid, Button } from '@mui/material';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import TypeIt from "typeit-react";
import Tilt from 'react-parallax-tilt';
import { fetchFromAPIKeys } from './apiService';

const Intro = () => {
  const [particlesLoaded, setParticlesLoaded] = useState(false);
  const [introDetails, setIntroDetails] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchFromAPIKeys('intro');
        setIntroDetails(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setParticlesLoaded(true);
    });
  }, []);

  const particlesOptions = useMemo(() => ({
    background: { color: { value: "#000000" } },
    fpsLimit: 120,
    interactivity: {
      events: { onClick: { enable: true, mode: "push" }, onHover: { enable: true, mode: 'repulse' } },
      modes: { push: { distance: 200, duration: 15 }, grab: { distance: 150 } }
    },
    particles: {
      color: { value: "#FFFFFF" },
      links: { color: "#FFFFFF", distance: 150, enable: true, opacity: 0.3, width: 1 },
      move: { direction: "none", enable: true, outModes: { default: "bounce" }, random: true, speed: 3, straight: false },
      number: { density: { enable: true }, value: 150 },
      opacity: { value: 1.0 },
      shape: { type: "circle" },
      size: { value: { min: 1, max: 3 } },
    },
    detectRetina: true,
  }), []);

  const theme = useTheme();

  return (
    <>
      <Box id='home' sx={{ position: 'relative', height: '100vh'}}>
        <Box className='Home' sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Box className='overlay'sx={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.2)',}} />
          {particlesLoaded && <Particles id='backWard' options={particlesOptions} />}
          {introDetails.map((intro, index) => (
            <React.Fragment key={index}>
              <Box padding='15vh 10vw' sx={{ [theme.breakpoints.down('sm')]: { paddingTop: '9vh' }, [theme.breakpoints.between('sm', 'lg')]: { padding: '15vh 5vw' } }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={3} order={{ xs: 2, md: 1 }}>
                    <Box sx={{ position: 'absolute',color: '#ffffff', [theme.breakpoints.down('sm')]: { paddingTop: '6vh' } }}>
                      <Typography sx={{ fontWeight: '700',fontSize: '3.4rem', [theme.breakpoints.down('sm')]: { fontSize: '1.8rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.4rem' } }}>
                        Hi There, <br /> I&apos;m {intro.firstName} <span style={{ color: 'yellow' }}>{intro.lastName}</span>
                      </Typography>
                      <Typography component="div" sx={{display: 'flex', fontWeight: '600',fontSize: '2.1rem', paddingTop: '1.9vh', [theme.breakpoints.down('sm')]: { fontSize: '1.4rem', fontWeight: '500' }, [theme.breakpoints.down('xs')]: { fontSize: '0.9rem' }}}>
                        <span>I&apos;m into </span>
                        <span style={{ color: 'yellow', marginLeft: '7px',  }}>
                          <TypeIt getBeforeInit={(instance) => { instance.type(intro.intoDesignation1).pause(750).delete().type(intro.intoDesignation2).pause(750).delete().type(intro.intoDesignation3).pause(750).delete().type(intro.intoDesignation4).pause(750).delete().type(intro.intoDesignation5).pause(750).delete(); return instance; }} options={{ speed: 100, loop: true, waitUntilVisible: true }}/>
                        </span>
                      </Typography>
                      <Box sx={{ marginTop: '2rem' }}>
                        <Button variant="contained" href={intro.linkTree} target="_blank" rel="noopener noreferrer" sx={{ fontSize: '1.5rem', borderRadius: '15px', boxShadow: '2px 4px 10px rgba(0, 0, 255, 0.9)', letterSpacing: '1px', padding: '0px 25px', transition: '0.3s ease', textTransform: 'none', '&:hover': { boxShadow: '2px 4px 10px rgba(255, 0, 255, 0.9)', color: 'yellow' }, [theme.breakpoints.up('sm')]: { fontWeight: '600' } }}>
                          Visit All Links
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9} order={{ xs: 1, md: 2 }} display='flex' justifyContent='end' alignItems='center' sx={{ [theme.breakpoints.down('md')]: { justifyContent: "center", paddingTop: '2vh' }, [theme.breakpoints.up('md')]: { marginLeft: '0vw' } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                      <Tilt scale={1.1} perspective={1000} gyroscope={true} tiltMaxAngleX={0} tiltMaxAngleY={0} transitionSpeed={900} >
                        <Avatar alt={intro.introImgAlt} src={intro.introImgSrc} sx={{ width: '33vh', height: '33vh', boxShadow: '0 0 30px rgba(121, 121, 255, 0.6)', transform: 'translateZ(1000px)', [theme.breakpoints.down('sm')]: { width: '23vh', height: '23vh' } }} />
                      </Tilt>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Intro;
