import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useTheme, Paper, Button } from '@mui/material';
import { motion } from 'framer-motion';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { fetchFromAPIKeys } from './apiService';

const About = () => {
  const theme = useTheme();
  const [aboutDetails, setAboutDetails] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchFromAPIKeys('about');
        setAboutDetails(data);
      } catch (error) {
        console.error("Error loading about:", error);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Box id="about" sx={{ backgroundColor: '#000000' }}>
        <Paper elevation={0} sx={{ margin: '0 4vw' }}>
          {aboutDetails.map((about, index) => (
            <React.Fragment key={index}>
              <Grid container spacing={0} sx={{ backgroundColor: '#000000', color: '#fff', minHeight: '58vh',  paddingBottom: '5vh' }}>
                <Grid item xs={12} md={12} order={{ xs: 1, md: 1 }} display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: '4vh'}}>
                  <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", fontWeight: '700', color: 'yellow', alignItems: "center", [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1rem' } }}>
                    <PersonRoundedIcon sx={{ fontSize: '3.2rem', color: 'whitesmoke', marginRight: '0.2rem', [theme.breakpoints.down('sm')]: { fontSize: '2.6rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.2rem' } }} /> About Me
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} order={{ xs: 2, md: 2 }} display="flex" justifyContent="center" alignItems="center" sx={{}}>
                  <Box p={3} boxShadow={5} borderRadius={21} style={{ overflow: 'hidden' }}>
                    <motion.div
                      initial={{ x: -1000, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <img
                        decoding="async"
                        width="auto"
                        height="auto"
                        src={about.imgSrc}
                        alt={about.imgAlt}
                        loading="lazy"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    </motion.div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} order={{ xs: 3, md: 3 }}>
                  <Box boxShadow={0} sx={{ padding: '5vh 1vw', [theme.breakpoints.down('sm')]: { padding: '5vw' }, }}>
                    <Typography variant="h5" gutterBottom sx={{ display: "flex", fontWeight: '600', color: 'yellow', [theme.breakpoints.down('sm')]: { fontSize: '1.4rem', fontWeight: '600' }, [theme.breakpoints.down('xs')]: { fontSize: '0.7rem' } }}>
                      {about.name}
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ display: "flex", textAlign: 'justify', fontWeight: '500', color: 'yellow', [theme.breakpoints.down('sm')]: { fontSize: '1rem', fontWeight: '500' }, [theme.breakpoints.down('xs')]: { fontSize: '0.4rem' } }}>
                      {about.designation}
                    </Typography>
                    <Typography variant="body1" sx={{ display: "flex", textAlign: 'justify',[theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                      {about.content1}
                    </Typography>
                    <Typography variant="body1" sx={{ display: "flex", textAlign: 'justify', paddingTop: '2vh', [theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                      {about.content2}
                    </Typography>
                    <Grid container spacing={0} sx={{ paddingTop: '2vh' }}>
                      <Grid item xs={0} sm={5} md={5.8}>
                        <Typography variant="body1" sx={{ display: "flex", [theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                          <strong>Age:</strong> &nbsp; {about.age}
                        </Typography>
                        <Typography variant="body1" sx={{ display: "flex", [theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                          <strong>Email:</strong> &nbsp; {about.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={0} sm={5} md={5.8}>
                        <Typography variant="body1" sx={{ display: "flex", [theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                          <strong>College:</strong> &nbsp; {about.college}
                        </Typography>
                        <Typography variant="body1" sx={{ display: "flex", [theme.breakpoints.down('sm')]: { fontSize: '0.94rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.5rem' } }}>
                          <strong>Location:</strong> &nbsp; {about.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} order={{ xs: 4, md: 4 }} display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: '1vh' }}>
                  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Button variant="contained" color="secondary" size="small" href={about.resume} target="_blank" rel="noopener noreferrer" sx={{ fontSize: '1.5rem', borderRadius: '13px', boxShadow: '2px 4px 10px rgba(0, 0, 255, 0.9)', letterSpacing: '1px', padding: '0px 25px', transition: '0.3s ease', textTransform: 'none', '&:hover': { boxShadow: '2px 4px 10px rgba(255, 0, 255, 0.9)', color: 'yellow' }, [theme.breakpoints.up('sm')]: { fontWeight: '600' } }}>
                      Resume
                    </Button>
                  </motion.div>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Paper>
      </Box>
    </>
  );
};

export default About;