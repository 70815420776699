import { useNavigate } from 'react-router-dom';
import { Box, useTheme, Hidden, Typography, Link, Divider } from "@mui/material";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import Error404 from '../assets/error404.gif';
import Footer from './components/Footer';

const NotFound = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <>
            <Box sx={{ backgroundColor: '#f0f0f0', width: '100%', minHeight: '100vh', margin: '0', padding: '0' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Hidden smDown>
                        <img src={Error404} alt="error404" />
                    </Hidden>
                    <Hidden smUp>
                        <img src={Error404} alt="error404" style={{ width: '80%' }} />
                    </Hidden>
                </Box>
                <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'GrayText', marginRight: '1rem', marginLeft: '1rem' }}>
                    <Typography variant="h3" fontWeight="300" gutterBottom sx={{ [theme.breakpoints.down('sm')]: { fontSize: '1.7rem'} }}>HTTP 404 - File not found</Typography>
                    <Typography variant="body1" gutterBottom sx={{ marginTop: '3rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem', fontWeight: '300'} }}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4rem', textAlign: 'center', marginRight: '1rem', marginLeft: '1rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href="/" style={{ textDecoration: 'none' }}>
                            <HomeRoundedIcon sx={{ color: 'GrayText', fontSize: '30px' }} />
                            <Typography variant="body1" fontWeight="300" color='GrayText' gutterBottom>Home</Typography>
                        </a>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '4rem', marginRight: '4rem', [theme.breakpoints.down('sm')]: { marginLeft: '3rem', marginRight: '3rem' } }}>
                        <Link to="#"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(-1);
                            }}
                            sx={{ textDecoration: 'none', cursor: 'pointer' }}
                        >
                            <BackspaceRoundedIcon sx={{ color: 'GrayText', fontSize: '30px' }} />
                            <Typography variant="body1" fontWeight="300" color='GrayText' gutterBottom>Go Back</Typography>
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <a href="contactMe" style={{ textDecoration: 'none' }}>
                            <ContactMailRoundedIcon sx={{ color: 'GrayText', fontSize: '30px' }} />
                            <Typography variant="body1" fontWeight="300" color='GrayText' gutterBottom>Contact Me</Typography>
                        </a>
                    </Box>
                </Box>
                <br /><br /><br />
                <Divider sx={{ marginY: 0, marginX: 9 }} />
            </Box >
            
            <Footer />
        </>
    );
};
export default NotFound;