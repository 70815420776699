import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import { fetchFromAPIKeys } from './apiService';

const Education = () => {
  const [educationDetails, setEducationDetails] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchFromAPIKeys('education');
        setEducationDetails(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    loadData();
  }, []);

  const theme = useTheme();

  return (
    <Box id="education" sx={{ backgroundColor: '#000000', color: '#fff', minHeight: '40vh', paddingTop: '5vh', paddingBottom: '5vh' }}>
        <Grid item xs={12} md={12} order={{ xs: 1, md: 1 }} display="flex" justifyContent="center" alignItems="center" sx={{ }}>
          <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", fontWeight: '700', color: 'yellow', alignItems: "center", [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1rem' } }}>
              <LocalLibraryRoundedIcon sx={{ fontSize: '3.2rem', color: 'whitesmoke', marginRight: '0.2rem', [theme.breakpoints.down('sm')]: { fontSize: '2.6rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.2rem' } }} /> Education
          </Typography>
        </Grid>
        <Box sx={{ padding:'0 4vw', [theme.breakpoints.down('sm')]: { padding: '0 1vw' } }} >
          {educationDetails.map((education, index) => (
              <React.Fragment key={index}>
              <Box p={2} boxShadow={0} display="flex" justifyContent="center" alignItems="center">
                <Paper elevation={24} sx={{ borderRadius: '19px', backgroundColor: '#000000', color: '#ffffff', width: '96%', justifyContent: 'center', alignItems: "center",  [theme.breakpoints.down('sm')]: { width: '100%' },}}>
                  <Grid container justifyContent="center">
                    <Grid container spacing={0}>
                      <Grid item xs={12} md={3} order={{ xs: 1, md: 1 }} backgroundColor="#000000" display="flex" justifyContent="center" alignItems="center">
                          <Box p={0} boxShadow={0} borderRadius={2} style={{ overflow: 'hidden' }}>
                            <motion.div initial={{ x: -1000, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 2 }}>
                              <img 
                                decoding="async" 
                                width="300" 
                                height="auto" 
                                src={education.image} 
                                alt={education.imageAlt} 
                                loading="lazy" 
                                style={{ maxWidth: '100%', height: 'auto', }}
                              />
                            </motion.div>
                          </Box>
                      </Grid>
                      <Grid item xs={12} md={9} order={{ xs: 2, md: 2 }} padding={2}>
                          <Box p={2} boxShadow={0} sx={{ [theme.breakpoints.down('sm')]: { padding: '0.6rem' }, }}>
                              <Typography variant="h6" gutterBottom sx={{ display: "flex", fontWeight: '600', [theme.breakpoints.down('sm')]: { fontSize: '1rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.8rem' } }}>
                                  {education.degree}
                              </Typography>
                              <Typography variant="body1" color={'grey'} gutterBottom sx={{ fontSize: '1.2rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.7rem' } }}>
                                  <a href={education.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>{education.institution}</a>
                              </Typography>
                              <Typography variant="body1" color={'purple'} fontWeight={600} gutterBottom sx={{ fontSize: '1.2rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.8rem' } }}>
                                  {education.year}
                              </Typography>
                          </Box>
                      </Grid>
                    </Grid>
                  </Grid>
              </Paper>
              </Box>
            </React.Fragment>
          ))}
        </Box>
    </Box>
  );
};

export default Education;
