import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';
import { fetchFromAPIKeys } from './apiService';

const Skills = () => {
    const [skillsDetails, setSkillsDetails] = useState([]);

    useEffect(() => {
      const loadData = async () => {
        try {
          const data = await fetchFromAPIKeys('skill');
          setSkillsDetails(data);
        } catch (error) {
          console.log(error.message);
        }
      };
      loadData();
    }, []);
  
  const theme = useTheme();

  return (
    <>
      <Box id="skills" sx={{ backgroundColor: '#000000', color: '#fff', minHeight: '40vh', paddingTop: '5vh', paddingBottom: '5vh' }}>
        <Grid item xs={12} md={12} order={{ xs: 1, md: 1 }} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", fontWeight: '700', color: 'yellow', alignItems: "center", [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1rem' } }}>
            <BuildCircleRoundedIcon sx={{ fontSize: '3.2rem', color: 'whitesmoke', marginRight: '0.2rem', [theme.breakpoints.down('sm')]: { fontSize: '2.6rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.2rem' } }} /> Skills
          </Typography>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '3vh', padding: '0 4vw' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: '700', color: 'yellow', [theme.breakpoints.down('sm')]: { fontSize: '0.69rem', fontWeight: '600' }, [theme.breakpoints.down('xs')]: { fontSize: '0.49rem' } }}>
                Here are some of the tools & technologies I had worked with: <span role="img" aria-label="emoji">🚀</span>
            </Typography>
        </Box>
        <Box sx={{ padding: '3vh', width: '80%', borderRadius: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
            <Grid container spacing={2} justifyContent="center">
                {skillsDetails.map((skill, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={6} sm={4} md={2}>
                            <motion.div whileHover={{ scale: 0.9 }}>
                                <Box p={2} boxShadow={20} borderRadius={2} sx={{ overflow: 'hidden', backgroundColor: 'rgba(255, 255, 255, 0.1)', width: '80%', height: 'auto', }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><img src={skill.icon} alt={skill.name} style={{ width: "40%", }} /></Box>
                                    <Typography variant="body1" align="center" fontWeight={600} fontSize={'0.7rem'} sx={{ color: 'white' }}>{skill.name}</Typography>
                                </Box>
                            </motion.div>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Skills;
