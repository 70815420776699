const API_BASE_URL = 'https://someshs.me/api/';

let isFetchingToken = false;

const getToken = async () => {
  if (isFetchingToken) return;
  isFetchingToken = true;

  try {
    const response = await fetch(`${API_BASE_URL}token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      })
    });

    const data = await response.json();
    localStorage.setItem('access', data.access);
    localStorage.setItem('refresh', data.refresh);
    return data;
  } finally {
    isFetchingToken = false;
  }
};

let isRefreshingToken = false;
let refreshPromise = null;

async function refreshToken() {
  if (refreshPromise) return refreshPromise;
  if (isRefreshingToken) return;
  isRefreshingToken = true;
  refreshPromise = (async () => {
    const refresh = localStorage.getItem('refresh');

    const response = await fetch(`${API_BASE_URL}token/refresh/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refresh })
    });

    const data = await response.json();
    if (response.ok) {
      localStorage.setItem('access', data.access);
      isRefreshingToken = false;
      refreshPromise = null;
      return data.access;
    } else {
      isRefreshingToken = false;
      refreshPromise = null;
      throw new Error(data.detail || 'Failed to fetch refresh token');
    }
  })();
  return refreshPromise;
}

async function fetchAPI(endpoint) {
  const fetchWithToken = async (token) => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(`${API_BASE_URL}${endpoint}/`, { headers });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized');
      }
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Network response was not ok');
    }

    return response.json();
  };

  try {
    let token = localStorage.getItem('access');
    return await fetchWithToken(token);
  } catch (error) {
    if (error.message === 'Unauthorized') {
      const newToken = await refreshToken();
      return fetchWithToken(newToken);
    } else {
      throw error;
    }
  }
}

const fetchFromAPIKeys = async (endpoint) => {
  const url = `${API_BASE_URL}${endpoint}`;
  const headers = new Headers({
    'Authorization': `Api-Key ${process.env.REACT_APP_API_KEYS}`
  });

  const response = await fetch(url, { headers: headers });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.json();
}


export { getToken, fetchAPI, fetchFromAPIKeys, refreshToken };
