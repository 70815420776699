import { Helmet } from 'react-helmet';
import { Box, Divider } from '@mui/material';
import NavBar from './components/NavBar';
import Info from './components/Info';
import Introduction from './components/Intro';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Education from './components/Education';
import Certification from './components/Certifications';
import Achievements from './components/Achievements';
import Awards from './components/Awards';
import Contact from './components/Contact';
import Footer from './components/Footer';

const pageTitle = 'Somesh Sharma | Portfolio';
const pageAuthor = 'Somesh Sharma';
const pageDescription = 'This is a Portfolio of Somesh Sharma. He is a Cyber Security Enthusiastic.';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="author" content={pageAuthor} />
        <meta name='description' content={pageDescription} />
        <meta name='robots' content='index, follow' />
      </Helmet>

      <NavBar />
      <Info />
      <Introduction />
      <About />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Experience />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Projects />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Skills />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Education />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Certification />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Achievements />
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "#000000" }}>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', height: 1, width: '69%', alignSelf: 'center' }} />
      </Box>
      <Awards />
      <Contact />
      <Footer />
    </>
  );
};

export default HomePage;
