import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Paper, useTheme, Snackbar, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContactMe from '../../assets/contact.webp';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSubmittedError, setIsFormSubmittedError] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://someshs.me/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${process.env.REACT_APP_API_KEYS}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsFormSubmitted(true);

        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
      } else {
        const errorData = await response.json();
        console.error(errorData);
        setIsFormSubmittedError(errorData.deatil || 'Something went wrong!');
      }
    } catch (error) {
      console.error('Error submitting form:', error, 'Please try again.');
      setIsErrorMessage(error.message || 'Something went wrong! Please try again.');
    }
    setFormData((prevData) => ({ ...prevData, name: '', email: '', subject: '', message: '' }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsFormSubmitted(false);
    setIsFormSubmittedError(false);
    setIsErrorMessage(false);
  };

  return (
    <>
      <Box id='contactMe' sx={{ position: 'relative', minHeight: '100vh', width: '100%', backgroundImage: `url(${ContactMe})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', overflow: 'hidden', paddingBottom: '7vh' }}>
        <Box className='overlay'sx={{position: 'absolute',top: 0,left: 0,width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.86)',}}></Box>
        <Paper className='content' elevation={0} sx={{position: 'absolute',top: '42%',left: '50%',width: '84%',transform: 'translate(-50%, -50%)',textAlign: 'center',justifyContent: 'center',padding: 2,backgroundColor: 'rgba(0, 0, 0, 0)',color: 'rgba(255, 255, 255, 1)',}}>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}open={isFormSubmitted} autoHideDuration={10000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success">
              Form submitted successfully!
            </Alert>
          </Snackbar>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isFormSubmittedError} autoHideDuration={10000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="warning">
              Declined by server to submit form!
            </Alert>
          </Snackbar>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isErrorMessage} autoHideDuration={10000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error">
              {isErrorMessage}
            </Alert>
          </Snackbar>
          <Container sx={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            <Grid item xs={12} md={12} order={{ xs: 1, md: 1 }} display="flex" justifyContent="center" alignItems="center" sx={{ }}>
              <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", fontWeight: '700', color: 'yellow', alignItems: "center", [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1rem' } }}>
                  <ContactSupportIcon sx={{ fontSize: '3.2rem', color: 'whitesmoke', marginRight: '0.2rem', [theme.breakpoints.down('sm')]: { fontSize: '2.6rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.2rem' } }} /> Get In Touch
              </Typography>
            </Grid>
            <Typography variant="body1" mt={2} mb={2} sx={{ fontWeight: '600', [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' } }}>
              Please feel free to send your message using the form below or reach me on my social media websites listed at the bottom of the page
            </Typography>
            <Typography variant="h5" mt={2} mb={2} sx={{ color: 'yellow', fontWeight: '600', [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' } }}>
              Have Something To Write?
            </Typography>
            <form onSubmit={handleFormSubmit}>
              <Box sx={{ mb: 3 }}>
                <TextField fullWidth label="Name" variant="outlined" placeholder="Your Name (required)" id="name" name="name" type='text' onChange={handleInputChange} value={formData.name} required InputProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' } }} InputLabelProps={{ style: { color: 'white' } }}/>
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField fullWidth label="Email" variant="outlined" placeholder="Your Email (required)" id="email" name="email" type="email" onChange={handleInputChange} value={formData.email} required InputProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' } }} InputLabelProps={{ style: { color: 'white' } }}/>
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField fullWidth label="Subject" variant="outlined" placeholder="Your Subject (required)" id="subject" name="subject" type='text' onChange={handleInputChange} value={formData.subject} required InputProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' } }} InputLabelProps={{ style: { color: 'white' } }}/>
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField fullWidth label="Message" variant="outlined" placeholder="Your Message (required)" id="message" name="message" type='text' onChange={handleInputChange} value={formData.message} multiline rows={5} required InputProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' } }} InputLabelProps={{ style: { color: 'white' } }}/>
              </Box>
              <Button type="submit" variant="contained" color="secondary" sx={{ fontSize: '1.5rem', borderRadius: '13px', boxShadow: '2px 4px 10px rgba(0, 0, 255, 0.9)', letterSpacing: '1px', padding: '0px 25px', transition: '0.3s ease', textTransform: 'none', '&:hover': { boxShadow: '2px 4px 10px rgba(155, 255, 255, 0.9)', color: 'yellow' }, [theme.breakpoints.up('sm')]: { fontWeight: '600' } }}>
                Submit
              </Button>
            </form>
          </Container>
        </Paper>
      </Box>
    </>
  );
};

export default Contact;
