import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, useTheme, Divider } from "@mui/material";
import { motion } from "framer-motion";
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import { fetchFromAPIKeys } from './apiService';

const Achievements = () => {
  const [achieveDetails, setAchieveDetails] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchFromAPIKeys('achievement');
        setAchieveDetails(data);
      } catch (error) {
        console.error("Error loading achievements:", error);
      }
    };

    loadData();
  }, []);

  const theme = useTheme();

  return (
    <>
    <Box id="achievements" sx={{ backgroundColor: '#000000', color: '#fff', minHeight: '40vh', paddingTop: '5vh', paddingBottom: '5vh' }}>
        <Grid item xs={12} md={12} order={{ xs: 1, md: 1 }} display="flex" justifyContent="center" alignItems="center" sx={{ }}>
        <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", fontWeight: '700', color: 'yellow', alignItems: "center", [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1rem' } }}>
            <VerifiedUserRoundedIcon sx={{ fontSize: '3.2rem', color: 'whitesmoke', marginRight: '0.2rem', [theme.breakpoints.down('sm')]: { fontSize: '2.6rem' }, [theme.breakpoints.down('xs')]: { fontSize: '1.2rem' } }} /> Achievements
        </Typography>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '2vh', padding: '0 4vw' }}>
            <Typography variant="h5" sx={{ fontWeight: '700', textAlign: 'center', color: 'yellow', [theme.breakpoints.down('sm')]: { fontSize: '0.69rem', fontWeight: '600' }, [theme.breakpoints.down('xs')]: { fontSize: '0.49rem' } }}>
              Here are the achievements and my commitment to outstanding performance: <span role="img" aria-label="emoji">🎯</span>
            </Typography>
        </Box>
        <Box sx={{ padding:'0 4vw', [theme.breakpoints.down('sm')]: { padding: '0 1vw' } }} >
          {achieveDetails.map((achieve, index) => (
          <React.Fragment key={index}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={3} order={{ xs: 1, md: 1 }} display="flex" justifyContent="end" alignItems="center" sx={{ [theme.breakpoints.down('md')]: { justifyContent: "center", paddingTop: '2vh' }, [theme.breakpoints.up('md')]: { marginLeft: '0vw' } }}>
                    <Box p={0} boxShadow={0} borderRadius={2} style={{ overflow: 'hidden' }}>
                    <motion.div
                        initial={{ x: -1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 2 }}>
                        <img
                          decoding="async"
                          width="400"
                          height="auto"
                          src={achieve.imageSrc}
                          alt={achieve.imageAlt}
                          loading="lazy"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </motion.div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9} order={{ xs: 2, md: 2 }} padding={2}>
                    <Box p={3} boxShadow={0} sx={{ [theme.breakpoints.down('sm')]: { padding: '0 6vw 6vw 6vw' }, }}>
                        <Typography variant="h6" gutterBottom sx={{ display: "flex", fontWeight: '600', [theme.breakpoints.down('sm')]: { fontSize: '1.1rem' }, [theme.breakpoints.down('xs')]: { fontSize: '0.6rem' } }}>
                          {achieve.title}
                        </Typography>
                        {achieve.issuedBy && (
                          <Typography variant="body1" color={'grey'} gutterBottom>
                              <strong>Issued By</strong>: {achieve.issuedBy}
                          </Typography>
                        )}
                        <Typography variant="body1" color={'grey'} gutterBottom dangerouslySetInnerHTML={{ __html: achieve.position }} />
                        {achieve.date && (
                          <Typography variant="body1" color={'grey'} gutterBottom>
                            <strong>Date</strong>: {achieve.date}
                          </Typography>
                        )}
                        {achieve.duration && (
                          <Typography variant="body1" color={'grey'} gutterBottom>
                            <strong>Duration</strong>: {achieve.duration}
                          </Typography>
                        )}
                        {achieve.file && (
                          <Typography variant="body1" color={'grey'} gutterBottom sx={{ marginBottom: '1rem' }}>
                            <strong>Certificate</strong>: {achieve.certVerificationLink && (<a href={achieve.certVerificationLink} target="_blank" rel="noopener noreferrer" style={{color: 'blue', textDecoration: 'none'}} onClick={(e) => e.currentTarget.style.color = 'blue'}>{achieve.certVerificationLabel}</a>)} {achieve.certVerificationLink && achieve.file && ' | '} {achieve.file && (<a href={achieve.file} target="_blank" rel="noopener noreferrer" style={{color: 'purple', textDecoration: 'none'}} onClick={(e) => e.currentTarget.style.color = 'purple'}>{achieve.downloadLabel}</a>)}
                          </Typography>
                        )}
                        {achieve.description.split('\n').map((line, index) => (
                          <Typography key={index} variant="body1" sx={{textAlign: 'justify'}} gutterBottom dangerouslySetInnerHTML={{ __html: line }} />
                        ))}
                    </Box>
                </Grid>
              </Grid>
              {index < achieveDetails.length - 1 && (
                <Divider sx={{ width: '75%', margin: 'auto' }}>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', '&::before, &::after': { content: '""', width: 3, height: 3, borderRadius: '100%', backgroundColor: '#FFFFFF', }, '&::before': { marginRight: 2, }, '&::after': { marginLeft: 0, }, '& .additional-dot': { content: '""', width: 3, height: 3, borderRadius: '100%', backgroundColor: '#FFFFFF', marginRight: 2, }, }}>
                    <Box className="additional-dot"></Box>
                    <Box className="additional-dot"></Box>
                    <Box className="additional-dot"></Box>
                    <Box className="additional-dot"></Box>
                    <Box className="additional-dot"></Box>
                  </Box>
                </Divider>
              )}
            </React.Fragment>
          ))}
        </Box>
    </Box>
    </>
);
};

export default Achievements;