import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, BottomNavigation, Stack, IconButton, Typography, Grid, Divider, useTheme, useMediaQuery } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { fetchFromAPIKeys } from './apiService';

const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchFromAPIKeys('link');
        setFooterLinks(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    loadData();
  }, []);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box id="Footer" sx={{ display: "flex", flexDirection: "column", paddingTop: '3vh', paddingBottom: '5vh', backgroundColor: "#000000" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6} textAlign={isMdUp ? 'center' : 'center'}>
          <Typography variant="h5" align="center" fontWeight={700} sx={{ color: 'white' }}>
            Somesh&apos;s Portfolio
          </Typography>
          <Typography variant="body2" align="center" sx={{ color: 'white', mt: 1 }}>
            Thank you for visiting my personal portfolio website. Connect with me over socials.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} textAlign={isMdUp ? 'right' : 'center'}>
          {footerLinks.map((link, index) => (
            <React.Fragment key={index}>
              <BottomNavigation sx={{ boxShadow: 0, backgroundColor: "#000000" }}>
                <Stack direction="row" spacing={3} justifyContent={isMdUp ? 'flex-end' : 'center'}>
                  <IconButton aria-label="LinkedIn" color="inherit" component={Link} to={link.LinkedInLink} rel="noopener noreferrer" target="_blank" sx={{ color: "#FFFFFF" }}>
                    <LinkedInIcon sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                  <IconButton aria-label="GitHub" color="inherit" component={Link} to={link.GitHubLink} rel="noopener noreferrer" target="_blank" sx={{ color: "#FFFFFF" }} >
                    <GitHubIcon sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                  <IconButton aria-label="Email" color="inherit" component={Link} to={`mailto:${link.EmailLink}`} rel="noopener noreferrer" target="_blank" sx={{ color: "#FFFFFF" }}>
                    <EmailIcon sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                  <IconButton aria-label="Instagram" color="inherit" component={Link} to={link.InstagramLink} rel="noopener noreferrer" target="_blank" sx={{ color: "#FFFFFF" }} >
                    <InstagramIcon sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                  <IconButton aria-label="Twitter" color="inherit" component={Link} to={link.TwitterLink} rel="noopener noreferrer" target="_blank" sx={{ color: "#FFFFFF" }}>
                    <TwitterIcon sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                </Stack>
              </BottomNavigation>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3, mb: 3, backgroundColor: 'rgba(255, 255, 255, 0.2)', height: 1, width: '70%', alignSelf: 'center' }} />
      <Typography variant="body2" align="center" sx={{ color: 'white' }}>
        Built With ❤️ By Somesh Sharma
      </Typography>
    </Box>
  );
};

export default Footer;
